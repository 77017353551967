import React from "react"
import * as styles from "./Control.module.scss"

const Control = ({ icon = false }) => {
  // render html
  return (
    <a href="/" className={styles.control} aria-label="Control button">
      {icon}
    </a>
  )
}

export default Control
