import { graphql } from "gatsby"
import React from "react"
import { Heading } from "../components/01_atoms/Heading/Heading"
import ImageTextBlock from "../components/03_organisms/ImageTextBlock/ImageTextBlock"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"
import BlogCard from "../components/02_molecules/BlogCard/BlogCard"
import Paging from "../components/02_molecules/Paging/Paging"
import Section from "../components/03_organisms/Section/Section"

import * as blogStyles from "../components/02_molecules/BlogCard/BlogCard.module.scss"

const BlogOverview = ({ data, pageContext }) => {
  const pageData = data.allNodeBlogOverview.nodes[0]

  return (
    <Layout>
      <Seo
        title={pageData.field_meta_title}
        description={pageData.field_meta_description}
      />

      {pageData && (
        <>
          <ImageTextBlock
            height="100"
            className="hero"
            image_position="left"
            background_color={pageData.relationships.field_main_color.name}
            slanted
            image={pageData.relationships.field_image}
          >
            <Heading size="xxl" spacing="m">
              {pageData.title}
            </Heading>
            {pageData.body.processed !== "<p>.</p>" && (
              <Paragraph>
                <span
                  dangerouslySetInnerHTML={{
                    __html: pageData.body.processed,
                  }}
                />
              </Paragraph>
            )}
          </ImageTextBlock>
          <Section content>
            <div className={blogStyles.cards}>
              {data.items.nodes.map(item => (
                <BlogCard
                  title={item.title}
                  image={item.relationships.field_image}
                  link={item.path.alias}
                  color={item.relationships.field_main_color.name}
                />
              ))}
            </div>
            {data.items.pageInfo.pageCount > 1 && (
              <Paging
                current={data.items.pageInfo.currentPage}
                total={data.items.pageInfo.pageCount}
                slug={pageContext.slug}
              />
            )}
          </Section>
        </>
      )}
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default BlogOverview

export const query = graphql`
  query ($language: String!, $skip: Int, $limit: Int) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allNodeBlogOverview(
      filter: { langcode: { eq: "en" }, status: { eq: true } }
    ) {
      nodes {
        title
        body {
          processed
        }
        field_image {
          drupal_internal__target_id
        }
        path {
          alias
        }
        field_meta_description
        field_meta_title
        relationships {
          field_image {
            ...imageFragment
          }
          field_main_color {
            name
          }
        }
        drupal_internal__nid
      }
    }

    items: allNodeBlog(
      filter: { langcode: { eq: "en" }, status: { eq: true } }
      sort: { fields: created, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      pageInfo {
        itemCount
        hasPreviousPage
        hasNextPage
        currentPage
        pageCount
        perPage
        totalCount
      }
      nodes {
        created(formatString: "DD/MM/YYYY")
        title
        field_image {
          drupal_internal__target_id
        }
        path {
          alias
        }

        relationships {
          field_image {
            ...imageFragment
          }
          field_main_color {
            name
          }
        }
      }
    }
  }
`
