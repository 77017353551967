import React from "react"

const IconArrowL = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.7 56.7">
      <path d="m39.7 56.7 9.5-9.5-18.9-18.8L49.2 9.5 39.7 0 11.4 28.4l28.3 28.3z" />
    </svg>
  )
}

export default IconArrowL
