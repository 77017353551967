import React from "react"

const IconArrowR = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.7 56.7">
      <path d="m20.9 0-9.5 9.5 18.9 18.8-18.9 18.9 9.5 9.5 28.3-28.4z" />
    </svg>
  )
}

export default IconArrowR
