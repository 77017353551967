export const colors = {
  Azure: "#F6F7F7",
  Chamois: "#E6CD96",
  Delta: "#979F8A",
  "Dusky Rose": "#E3BAA7",
  "Grain Brown": "#CDBBA7",
  Grenadier: "#CB4636",
  "Grey Chateau": "#9FA0A1",
  "Primary Blue": "#000037",
  "Regent St Blue": "#A7CBD9",
  White: "#FFFFF",
}
